<template>
  <div class="about flex-grow mx-auto p-6 sm:p-8 h-full bg-gray-200">
    <img class="mx-auto" src="@/assets/logo.png" alt="">
    <h1 class="font-black sm:text-3xl text-2xl">10km lockdown limit</h1>
    <p class="mt-5 text-left sm:text-2xl text-xl">
      Since 13 Jan 2021, Malaysia government implement MCO, CMCO and RMCO to fight against COVID-19. Those implemented MCO States have set (10) ten-kilometre travel limit from house. This map will show you 10km lockdown limit. You can move around the pin to locate your house position, so that you can monitor or plan what place you can travel.
    </p>
    <p class="mt-5 text-center sm:text-2xl text-xl">
      Stay safe!
    </P>
  </div>
</template>
